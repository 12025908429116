import React, { useMemo } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Select, Tabs } from "antd";
import FlatCard from "components/FlatCard/FlatCard";

const { useState } = require("react");

export default function TabsRelacionCostesCampana({
    filterCampana = "",
    campanas = [],
    grupos = [], 
    setGrupos = () => {},
    activeKey = "",
    setActiveKey = () => {},
    setCampanasEliminadas = () => {}
}) {

    const [ searchFilter, setSearchFilter ] = useState({ gestor: "", plataforma: "" });

    const [ edit, setEdit ] = useState(false);
    const [ title, setTitle ] = useState("");

    const renameKey = (obj, oldKey, newKey) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            acc[key === oldKey ? newKey : key] = value;
            return acc;
        }, {});
    }
    const saveTitle = (lastTitle, newTitle) => {
        const newGroups = renameKey(grupos, lastTitle, newTitle);
        setEdit(false);
        setGrupos(newGroups);
        setActiveKey(newTitle);
    }

    const FormatLine = ({id, nombre, groupKey, isGestor = false}) => {

        const deleteCampaign = () => {
            if (isGestor) setCampanasEliminadas(prev => [ ...prev, id ]);
            const field = isGestor ? "campanasGestor" : "campanasRelacionadas";
            const idField = isGestor ? "_id" : "id";
            grupos[groupKey][field] = grupos[groupKey][field].filter(_campana => _campana[idField] !== id);
            setGrupos({...grupos});
        }

        return <div className="fdr jcc aic p3 mb1 mr3" style={{ borderRadius: 5, height: "35px", border: "1px solid lightgray" }}> 
            <div className="w90"> {id} - {nombre} </div> 
            <div className="jcfe w10">
                <Popconfirm
                    onConfirm={() => deleteCampaign()}
                    title="¿Estás seguro de eliminar esta campaña?"
                    okText="Sí"
                    cancelText="No"
                >
                    <DeleteOutlined className="fs5" style={{color: "red"}} />
                </Popconfirm>
            </div> 
        </div>
    }

    const searchCampana = (_key) => {

        const encuentraCampanaGestor = grupos[_key].campanasGestor.find(_x => _x.nombre.toLowerCase().includes(filterCampana.toLowerCase()) || _x._id.toString().toLowerCase().includes(filterCampana.toLowerCase()) );
        const encuentraCampanaPlataforma = grupos[_key].campanasRelacionadas.find(_x => _x.nombre.toLowerCase().includes(filterCampana.toLowerCase()) || _x.id.toString().includes(filterCampana.toLowerCase()) );

        return encuentraCampanaGestor || encuentraCampanaPlataforma;
    }
    const isCampaignInUse = (id_campana) => {
        const usadas = [];
        Object.keys(grupos).forEach(_key => {
            const { campanasGestor, campanasRelacionadas} = grupos[_key];
            usadas.push(
                ...(campanasGestor.map(_campana => ({ id: _campana._id, group: _key }))), 
                ...(campanasRelacionadas.map(_campana => ({ id: _campana.id, group: _key })))
            );
        })
        const campaign = usadas.find(_campana => _campana.id === id_campana);
        return { used: Boolean(campaign), ...(campaign || {}) };
    }
    
    const BuscadorCampana = ({defaultValue: _defaultValue = null, groupKey}) => {

        const [campanaSeleccionada, setCampanaSeleccionada] = useState(null);
        const [defaultValue, setDefaultValue] = useState(_defaultValue);

        const addCampaign = () => {
            const parsedCampaign = JSON.parse(campanaSeleccionada);
            const target = defaultValue === "gestor" ? "campanasGestor" : "campanasRelacionadas";

            if (defaultValue === "gestor") {
                parsedCampaign._id = parsedCampaign.id;
                delete parsedCampaign.id;
            }

            grupos[groupKey][target].push(parsedCampaign);
            setGrupos({...grupos});
        }

        return <div className="fdc w100 h100">
            <div className="w100 fdr jcsb aic">
                <Select className="w20" value={defaultValue} placeholder="Plataforma" disabled={_defaultValue != null} onChange={_x => setDefaultValue(_x)}>
                    <Select.Option key="google" value="google">Google</Select.Option>
                    <Select.Option key="meta" value="meta">Meta</Select.Option>
                    <Select.Option key="gestor" value="gestor">Gestor</Select.Option>
                </Select>
                <Select className="w50" showSearch disabled={!campanas[defaultValue]} placeholder="Selecciona una campaña" onChange={_x => setCampanaSeleccionada(_x) }>
                    {campanas[defaultValue] 
                        ? campanas[defaultValue]
                            ?.filter(_x => !isCampaignInUse(_x.id||_x._id).used )
                            ?.map(_x => <Select.Option key={_x.id || _x._id} value={JSON.stringify({ id: _x.id || _x._id, nombre: _x.name || _x.nombre, origen: defaultValue })}>{ _x.name || _x.nombre }</Select.Option>) 
                        : []
                    }
                </Select>
                <Button type="primary" disabled={!Boolean(campanaSeleccionada)} onClick={() => addCampaign()}>Añadir</Button>
            </div>
        </div>        
    }

    let set = false;
    const keys = Object.keys(grupos);
    const arr = keys.map((_key, _indx) => {

        if (filterCampana && !searchCampana(_key)) return;
        if (!set && !activeKey) {
            set = true;
            setActiveKey(_key);
        }

        const origen = grupos[_key]?.campanasRelacionadas['0']?.origen;
        const campanasGestor = grupos[_key].campanasGestor.filter(_x => _x?.nombre?.includes(searchFilter.gestor)).map(_campana => <FormatLine id={_campana._id} nombre={_campana.nombre} groupKey={_key} isGestor={true} />);
        const campanasRelacionadas = grupos[_key].campanasRelacionadas.filter(_x => _x?.nombre?.includes(searchFilter.plataforma)).map(_campana => <FormatLine id={_campana.id} nombre={_campana.nombre} groupKey={_key} />);

        const nameLimit = 16;
        const name = _key.substring(0, nameLimit) + ( _key.length > nameLimit ? "..." : "");

        return <Tabs.TabPane tab={name} key={_key}>
            <div className="fdc w100 h100" style={{ gap: 20, display: 'flex', height: "800px" }}>
                <div className="w100 jcc aic fs5">  
                    {
                        edit
                            ? <div className="fdr jcc aic">
                                <Input value={title} onChange={(ev) => setTitle(ev.target.value) }/>
                                <Button className="ml3" type="primary" onClick={() => saveTitle(_key, title)}> Guardar </Button>
                                <Button className="ml3" type="primary" onClick={() => { setEdit(false); setTitle(_key); }} danger> Cancelar </Button>
                            </div>
                            : <div className="fdr jcc aic">
                                <EditOutlined className="fs5 mr3" onClick={() => { setEdit(true); setTitle(_key) }} style={{ cursor: "pointer" }}/> 
                                <h2> {_key} </h2> 
                            </div>
                    }
                </div>
                <div className="fdc h50" style={{ padding: 20, paddingTop: 0 }}>
                    <FlatCard mainClassName="mb4"> <BuscadorCampana defaultValue={"gestor"} groupKey={_key}/> </FlatCard>
                    <FlatCard> 
                        <Input className="mb4" placeholder="Filtrar campañas" onChange={(ev) => setSearchFilter(prev => ({ ...prev, gestor: ev.target.value }))} />
                        <div className="fdc" style={{ height: "250px", overflowY: "scroll" }}> {campanasGestor} </div> 
                    </FlatCard>
                </div>
                <div className="fdc h50" style={{ padding: 20, paddingTop: 0 }}>
                    <FlatCard mainClassName="mb4"> <BuscadorCampana defaultValue={origen?.toLowerCase() || null} groupKey={_key}/> </FlatCard>
                    <FlatCard> 
                        <Input className="mb4" placeholder="Filtrar campañas" onChange={(ev) => setSearchFilter(prev => ({ ...prev, plataforma: ev.target.value }))} />
                        <div className="fdc" style={{ height: "250px", overflowY: "scroll" }}>  {campanasRelacionadas}  </div> 
                    </FlatCard>
                </div>
            </div>
        </Tabs.TabPane>
    });

    return <Tabs tabPosition="left" style={{ zIndex: 6, height: 1000 }} activeKey={activeKey} onTabClick={(val) => val && setActiveKey(val)}> { arr } </Tabs>
    
}
