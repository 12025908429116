import React from "react";
import { CheckOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import Void from "atomicComponents/Void";
import { useEffect, useState } from "react";
import apiRequest from "utils/apiRequest";
import TabsRelacionCostesCampana from "./TabsRelacionCostesCampana";

/**
 * 
 * Las campañas tienen "camapansRelacionadas". Estas son las referencias a las campañas de las plataformas ( Google - Meta ).
 * Estas campañas funcionan por grupos. Vamos a coger las campañas, agruparlas y ver los grupos creados.
 * Al modificar un grupo se van a modificar todas las campañas relacionadas con ese grupo
 * 
 * @returns 
 */
// TODO: RENAME GROUPS
export default function RelacionCostesCampanas(){

    const [loading, setLoading] = useState(true);

    const [buscadorGeneral, setBuscadorGeneral] = useState("");

    const [grupos, setGrupos] = useState({});

    const [campanas, setCampanas] = useState([]);
    const [campanasEliminadas, setCampanasEliminadas] = useState([]);

    const [activeKey, setActiveKey] = useState("");
    const [fadeInLoading, setFadeInLoading] = useState(false);
    const [showTabs, setShowTabs] = useState(false);

    const [petitionStatus, setPetitionStatus] = useState({ google: false, meta: false, grupos: false });

    useEffect(() => {
        setTimeout(() => setFadeInLoading(true), 200);
        setTimeout(() => setShowTabs(true), 1000);
        loadInitialData();
    }, []);


    const loadInitialData = async () => {
        await Promise.all([ getGoogleCampaigns(), getMetaCampaigns(), getGrupos() ]);
        setFadeInLoading(false);
        setTimeout(() => setLoading(false), 1000);
    }


    const getGrupos = async () => {
        try {

            const _campanas = await apiRequest('get', `/campana`, null, true);

            const grupos = {};
            _campanas.filter(_cam => Boolean(_cam?.campanas_relacionadas?.length)).forEach(_campana => {
                const id = _campana._id;
                const objCampana = { _id: id, nombre: _campana.nombre };
                const grupo = _campana.campanas_relacionadas_group;
                if (!grupos[grupo]) {
                    grupos[grupo] = { campanasGestor: [ objCampana ], campanasRelacionadas: _campana.campanas_relacionadas };
                } else {
                    grupos[grupo].campanasGestor.push(objCampana);
                }
            });

            const firstKey = Object.keys(grupos)[0] ?? "";

            setGrupos(grupos);
            setCampanas(prev => ({...prev, gestor: _campanas}));

            setPetitionStatus(prev => ({ ...prev, grupos : true }));
            setActiveKey(firstKey);

        } catch (err) {
            message.error("Error al obtener las campañas");
        }
    }
    const getGoogleCampaigns = async () => {
        try {

            const { ok, campaigns } = await apiRequest('get', `/googleCampaigns`, null, true);
            setCampanas(prev => ({...prev, google: campaigns}));
            setPetitionStatus(prev => ({ ...prev, google : true }));

        } catch (err) {
            message.error("Error al obtener las campañas de Google");
        }
    }
    const getMetaCampaigns = async () => {
        try {

            const { ok, campaigns } = await apiRequest('get', `/metaCampaigns`, null, true);
            setCampanas(prev => ({...prev, meta: campaigns}));
            setPetitionStatus(prev => ({ ...prev, meta : true }));

        } catch (err) {
            message.error("Error al obtener las campañas de Meta");
        }
    }


    const saveChanges = async () => {
        try {

            setLoading(true);

            const eliminadas = [ ...new Set(campanasEliminadas) ];
            const { ok } = await apiRequest( "post", "/cambiosCampanasGestorPlataforma", { eliminadas, grupos }, true );
            if (!ok) throw Error("No se han podido guardar los cambios");
            message.success("Cambios guardados correctamente!");

        } catch (err) {
            message.error("Error al guardar los cambios");
        } finally {
            setLoading(false);
        }
    }


    return <div className="fdc w100 jcc aic">

        <div style={{ width: 1200 }}>

            <div>
                <div className="fdc flex mt4">
                    <span className="flex fs5 bold mr2 mb2"> CAMPAÑAS RELACIONADAS - COSTES PROGRAMADOS </span>
                    <span className="flex fs2 bold mr2"> Relacion entre las campañas del gestor y las campañas de plataforma. Esta relacion se utiliza para recoger los datos de la plataforma en un periodo concreto de tiempo y repartirlo en base a los leads de cada campaña </span>
                </div>

                <Void space={1} />
                <Void space={0} divider={true} />
            </div>

            <div className="fdc h10 mt5">

                {
                    showTabs && <div style={{ transition: "all 0.3s ease-in-out" }}>
                    
                    <div style={{ height: 60 }}>
                        <Button type="primary" className="w10 mb5" style={{ position: "absolute" }} onClick={() => setGrupos( prev => ({ ...prev, nuevoGrupo: { campanasGestor: [], campanasRelacionadas: [] } }) )}> Añadir grupo </Button>
                        <div className="w100 jcc aic"> <Input style={{  width: 400 }} placeholder="Buscador general de campañas..." onChange={(ev) => {setBuscadorGeneral(ev.target.value); setActiveKey(""); }}/> </div>
                    </div>

                    <Void divider={true} />
                    <Void space={2} />

                    <TabsRelacionCostesCampana filterCampana={buscadorGeneral} campanas={campanas} grupos={grupos} setGrupos={setGrupos} activeKey={activeKey} setActiveKey={setActiveKey} setCampanasEliminadas={setCampanasEliminadas}/>

                    <div className="jcfe">
                        <Button type="primary" onClick={() => saveChanges()}> Guardar </Button>
                    </div>
                </div>
                }

                <div className="fdc jcc aic" style={{ zIndex: loading ? 0 : -20, position: "absolute", width: 1200, height: "100%", backgroundColor: "white", backdropFilter: "blur(2px)", transition: "all 0.3s ease-in-out", opacity: fadeInLoading ? 1 : 0 }}> 
                    {
                        loading &&  <div className="fdc jcc aic">
                            <div className="mb5">
                                <p> Obteniendo grupos - { petitionStatus.grupos ? <CheckOutlined /> : <SyncOutlined spin /> } </p>
                                <p> Obteniendo campañas de meta - { petitionStatus.meta ? <CheckOutlined /> : <SyncOutlined spin /> } </p>
                                <p> Obteniendo campañas de google - { petitionStatus.google ? <CheckOutlined /> : <SyncOutlined spin /> } </p>
                            </div>
                            <LoadingOutlined style={{ fontSize: 50 }}/> 
                        </div>
                    }
                    <div className="fdc jcc aic" style={{ zIndex: setFadeInLoading ? 0 : -30, position: "absolute", width: 1200, height: "100%", backgroundColor: "white", transition: "all 0.3s ease-in-out", opacity: fadeInLoading ? 0 : 1 }}> </div>
                </div>

            </div>        

        </div>

        <Void space={10} />

    </div>

}